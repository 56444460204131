<template>
  <g fill="none" fill-rule="evenodd">
    <g stroke="#79838E" stroke-width="1.167">
      <g>
        <path
          d="M1.75 2.528l.73 11.666c.034.675.524 1.167 1.166 1.167h6.708c.644 0 1.126-.492 1.167-1.167l.729-11.666M.583 2.528L13.417 2.528M4.667 2.528V1.069c0-.115.022-.229.066-.335.043-.106.108-.203.19-.284.08-.081.177-.146.283-.19.107-.044.22-.066.336-.066h2.916c.115 0 .23.022.336.066.106.044.202.109.284.19.081.081.146.178.19.284.043.106.066.22.065.335v1.459M7 4.861L7 13.028M4.375 4.861L4.667 13.028M9.625 4.861L9.333 13.028"
          transform="translate(-341 -233) translate(341 234)"
        />
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
